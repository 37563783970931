<div *ngIf="searchField" class="form-group w-100 pr-2 pl-2 my-2">
  <input type="search"
         class="form-control w-100"
         (click)="$event.stopPropagation();"
         placeholder="{{ 'submission.sections.general.search-collection' | translate }}"
         [formControl]="searchField"
         #searchFieldEl>
</div>
<div class="dropdown-divider m-0"></div>
<ul class="scrollable-menu p-0 m-0"
    role="menu"
     (scroll)="onScroll($event)"
     infiniteScroll
     [infiniteScrollDistance]="1.5"
     [infiniteScrollThrottle]="0"
     [infiniteScrollUpDistance]="1.5"
     [fromRoot]="true"
     [scrollWindow]="false"
     (scrolled)="onScrollDown()">

  <li class="dropdown-item disabled" role="menuitem" *ngIf="searchListCollection?.length == 0 && !(isLoading | async)">
    {{'submission.sections.general.no-collection' | translate}}
  </li>
  <ng-container *ngIf="searchListCollection?.length > 0">
    <li *ngFor="let listItem of searchListCollection"
            class="dropdown-item collection-item"
            role="menuitem"
            title="{{ listItem.collection.name }}"
            (click)="onSelect(listItem)">
      <div class="list-unstyled mb-0">
        <div class="list-item text-truncate text-secondary" *ngFor="let item of listItem.communities">
          {{ item.name}} <i class="fa fa-level-down" aria-hidden="true"></i>
        </div>
        <div class="list-item text-truncate text-primary font-weight-bold">{{ listItem.collection.name}}</div>
      </div>
    </li>
  </ng-container>
  <li *ngIf="(isLoading | async)">
    <button class="dropdown-item disabled">
      <ds-themed-loading message="{{'loading.default' | translate}}">
      </ds-themed-loading>
    </button>
  </li>
</ul>
