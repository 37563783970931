<div class="background-image-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
		  <!--<h1 class="display-3">Наукова електронна бібліотека періодичних видань НАН України</h1> -->
		  <p class="lead"><b>Наукова електронна бібліотека періодичних видань НАН України</b> є бібліотекою відкритого доступу і передбачає безкоштовний доступ читачів до наукової інформації в Інтернет з правом читати, завантажувати, 
		  копіювати, розповсюджувати, друкувати, проводити пошук, посилатися на повнотекстові статті, індексувати, тобто використовувати її з законною ціллю без фінансових, юридичних і технічних перешкод, що відповідає 
		  Будапештській Ініціативі Відкритого Доступу. <b>ISSN:</b> 2310-8479 </p>
		  <p class="lead"><b>Поточний стан:</b> 484 журналів, 9.575 випусків, 183.499 статей, 130.839.864 переглядів, 130.493.145 завантажень</p>
        <!--
		<h1 class="display-3">National Academy of Sciences Periodicals Library</h1>         
		<p class="lead">DSpace is the world leading open source repository platform that enables organisations to:</p> 
		-->
        </div>
      </div>
	  <!--
      <ul>
        <li>easily ingest documents, audio, video, datasets and their corresponding Dublin Core
          metadata
        </li>
        <li>open up this content to local and global audiences, thanks to the OAI-PMH interface and
          Google Scholar optimizations
        </li>
        <li>issue permanent urls and trustworthy identifiers, including optional integrations with
          handle.net and DataCite DOI
        </li>
      </ul>
      <p>Join an international community of <a href="https://wiki.lyrasis.org/display/DSPACE/DSpace+Positioning" target="_blank">leading institutions using DSpace</a>.</p>
      <p>The test user accounts below have their password set to the name of this
        software in lowercase.</p>
      --> 
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner-half.webp 1200w, assets/dspace/images/banner-tall.webp 768w">
    <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner-half.jpg 1200w, assets/dspace/images/banner-tall.jpg 768w">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
  <!-- <small class="credits">Photo by <a href="https://www.pexels.com/@inspiredimages">@inspiredimages</a></small> -->
</div>
