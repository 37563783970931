<div class="container">
    <h1 id="header">{{'system-wide-alert.form.header' | translate}}</h1>
    <div [formGroup]="alertForm" [class]="'ng-invalid'">
        <div class="form-group">
            <div class="row mb-2">
                <div class="col">
                    <ui-switch [checkedLabel]="'system-wide-alert.form.label.active' | translate"
                        [uncheckedLabel]="'system-wide-alert.form.label.inactive' | translate"
                        [checked]="formActive.value" (change)="setActive($event)"></ui-switch>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="formMessage">{{ 'system-wide-alert.form.label.message' | translate }}</label>
                    <textarea id="formMessage" rows="5"
                        [className]="(formMessage.invalid) && (formMessage.dirty || formMessage.touched) ? 'form-control is-invalid' :'form-control'"
                        formControlName="formMessage">
                </textarea>
                    <div *ngIf="formMessage.invalid && (formMessage.dirty || formMessage.touched)"
                        class="invalid-feedback show-feedback">
                        <span *ngIf="formMessage.errors">
                            {{ 'system-wide-alert.form.error.message' | translate }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col mb-2 d-flex align-items-end">
            <ui-switch size="small" [ariaLabel]="'system-wide-alert.form.label.countdownTo.enable' | translate"
                [checked]="counterEnabled$ |async" (change)="setCounterEnabled($event)"></ui-switch>
            <span class="ml-2">{{ 'system-wide-alert.form.label.countdownTo.enable' | translate }}</span>
        </div>
    </div>
    <div *ngIf="counterEnabled$ |async">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="date"
                        [minDate]="minDate" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="updatePreviewTime()" />
                    <button [attr.aria-label]="'system-wide-alert-form.select-date-by-calendar' | translate"
                        (click)="d.toggle()" class="btn btn-outline-secondary fas fa-calendar" type="button">
                    </button>
                </div>
            </div>
            <div class="col-12 d-md-none">
                <div class="input-group">
                    <ngb-timepicker [(ngModel)]="time" (ngModelChange)="updatePreviewTime()"></ngb-timepicker>
                </div>
            </div>
            <div class="d-none d-md-block col-md-6 timepicker-margin">
                <div class="input-group">
                    <ngb-timepicker [(ngModel)]="time" (ngModelChange)="updatePreviewTime()"></ngb-timepicker>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-2">
        <span class="text-muted">{{ 'system-wide-alert.form.label.countdownTo.hint' | translate }}</span>
    </div>
    <div *ngIf="formMessage.value">
        <div class="row">
            <div class="col">
                <label>{{ 'system-wide-alert.form.label.preview' | translate}}</label>
            </div>
        </div>
        <div class="rounded-0 alert alert-warning">
            <span class="font-weight-bold">
                <span *ngIf="previewDays > 0 || previewHours > 0 || previewMinutes > 0 ">
                    {{ 'system-wide-alert-banner.countdown.prefix' | translate }}
                </span>
                <span *ngIf="previewDays > 0">
                    {{ 'system-wide-alert-banner.countdown.days' | translate: { days: previewDays } }}
                </span>
                <span *ngIf="previewDays > 0 || previewHours > 0 ">
                    {{ 'system-wide-alert-banner.countdown.hours' | translate: { hours: previewHours } }}
                </span>
                <span *ngIf="previewDays > 0 || previewHours > 0 || previewMinutes > 0 ">
                    {{ 'system-wide-alert-banner.countdown.minutes' | translate: { minutes: previewMinutes } }}
                </span>
            </span>
            <span [innerHTML]="formMessage.value"></span>
        </div>
    </div>
    <div class="btn-row float-right space-children-mr mt-2">
        <button (click)="back()" class="btn btn-outline-secondary">
            <i class="fas fa-arrow-left"></i> {{ 'system-wide-alert.form.cancel' | translate }}
        </button>
        <button class="btn btn-primary" [disabled]="alertForm.invalid" (click)="save()">
            <i class="fa fa-save"></i> {{ 'system-wide-alert.form.save' | translate }}
        </button>
    </div>
</div>