<div class="container">
  <div class="row">
    <div class="row-with-sidebar row-offcanvas row-offcanvas-left"
         [@pushInOut]="(isSidebarCollapsed$ | async) ? 'collapsed' : 'expanded'">
      <div id="{{id}}-sidebar-content"
           [class.invisible]="(isSidebarCollapsed$ | async) === true && (isXsOrSm$ | async) === true"
           class="col-12 col-md-{{sideBarWidth}} sidebar-content {{sidebarClasses | async}}">
        <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
      </div>
      <div class="col-12 col-md-{{12 - sideBarWidth}}"
           [class.invisible]="(isSidebarCollapsed$ | async) === false && (isXsOrSm$ | async) === true">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
