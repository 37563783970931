<ng-container *ngIf="(isMobile$ | async) && (isAuthenticated$ | async)">
  <ds-user-menu [inExpandableNavbar]="true"></ds-user-menu>
</ng-container>
<div class="navbar-nav h-100 align-items-md-stretch gapx-3" role="menubar" id="main-site-navigation" [ngClass]="(isMobile$ | async) ? 'navbar-nav-mobile' : 'navbar-nav-desktop'">
  <div><h4>National Academy of Sciences Periodicals Library</h4></div>
  <ng-container *ngFor="let section of (sections | async)">
    <ng-container
      *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
  </ng-container>
</div>
