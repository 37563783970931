<div>
  <a class="sidebar-section-wrapper"
     [ngClass]="{ disabled: isDisabled }"
     role="menuitem"
     [attr.aria-disabled]="isDisabled"
     [attr.aria-labelledby]="adminMenuSectionTitleId(section.id)"
     [routerLink]="itemModel.link"
     (keyup.space)="navigate($event)"
     (keyup.enter)="navigate($event)"
     href="javascript:void(0);"
  >
    <div class="sidebar-fixed-element-wrapper" data-test="sidebar-section-icon" aria-hidden="true">
      <i class="fas fa-{{section.icon}} fa-fw"></i>
    </div>
    <div class="sidebar-collapsible-element-outer-wrapper">
      <div class="sidebar-collapsible-element-inner-wrapper sidebar-item">
        <span [id]="adminMenuSectionTitleId(section.id)">{{itemModel.text | translate}}</span>
      </div>
    </div>
  </a>
</div>
